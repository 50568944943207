<template>
<div class="signin">
    <div class="popup">
        <div class="popup-container">
            <a @click="closeSigninPop()" class="cls"><div class="cls-btn">X</div></a>
            <div class="content">
                <h1>SIGN IN</h1>
                <p>If you don't have an account. Please continue to signup.</p>
                <div class="image">
                    <img src="../svg/Artboard.png" alt="">
                </div>
                <form v-on:submit="signin()" class="form">
                    <div class="fields">
                        <div class="field">
                            <label>Email</label>
                            <input type="email" v-model="userInfo.email" required>
                        </div>
                    </div>
                    <div class="fields">
                        <div class="field">
                            <label>Password</label>
                            <input type="password" v-model="userInfo.password" required>
                        </div>
                    </div>
                    <!-- <div class="fields">
                        <a href="#" class="forgot">Forgot Password?</a>
                    </div> -->
                    <p v-if="this.$store.state.error" style="color:red">Email / Password is invalid</p>
                    <button type="submit">Login</button>
                    <a @click="openSignupPop()" class="signup">Don't have an account? <span>Signup</span></a>
                    <a class="term" @click="changeRoute('/content/terms-and-conditions')">By signing up, you are agree with our <span> Terms & Conditions</span></a>
                </form>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data(){
        return{
            userInfo:{},
            error: false
        }
    },
    created(){
        this.userInfo = Object.assign({
            event: this.$store.state.event,
            table: "users"
        });   
    },
    methods:{
        closeSigninPop(){
            document.getElementById('signinContainer').classList.remove('showSigninContainer'); 
        },
        openSignupPop(){
            document.getElementById('signinContainer').classList.remove('showSigninContainer'); 
            document.getElementById('signupContainer').classList.add('showSignupContainer'); 
        },
        signin(e){
            event.preventDefault()
            this.error = false
            this.$store.dispatch("SignIn", this.userInfo).then(response => {
                setTimeout(() => {
                    if(!this.$store.state.error){
                        this.closeSigninPop()
                        this.$router.push({path:'/page/myaccount'})
                    }
                 }, 500);
            })
        },
        changeRoute(path){
            document.getElementById('signinContainer').classList.remove('showSigninContainer'); 
            this.$router.push({path: path})
        },
    }
}
</script>

<style scoped>
</style>